<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">项目管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">项目列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ title }}</span>
      </div>
      <!-- <el-upload
        style="text-align: left; margin-bottom: 30px"
        class="upload-demo"
        action
        :multiple="false"
        :limit="1"
        :auto-upload="false"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :on-change="importExcel"
        :file-list="uploadFiles"
      >
        <el-button size="small" type="primary">上传模板</el-button>
      </el-upload>-->
    </div>
    <el-card style="padding: 20px">
      <el-form
        label-position="right"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="100px"
        style="text-align: left"
      >
        <Divider :title="'项目基础信息'"></Divider>
        <el-col :span="12">
          <el-form-item label="项目名称" label-width="110px" prop="name">
            <el-input v-model="ruleForm.name" style="width: 100%" placeholder="请输入项目名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="项目负责人" label-width="110px" prop="subjectCode">
            <el-select
              style="width: 100%"
              filterable
              :disabled="isEdit"
              v-model="ruleForm.subjectCode"
              placeholder="请选择"
            >
              <el-option
                :label="item.name || item.nickname"
                :value="item.code"
                v-for="(item, i) in userList"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合作企业" label-width="110px" prop="companyName">
            <el-input v-model="ruleForm.companyName" style="width: 100%" placeholder="请输入合作企业"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同额/元" label-width="110px" prop="contractMoney">
            <el-input
              v-model="ruleForm.contractMoney"
              type="number"
              style="width: 100%"
              placeholder="请输入合同额"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="项目类型" label-width="110px" prop="type">
            <el-input
              v-model="ruleForm.type"
              :disabled="isEdit"
              placeholder="请输入项目类型"
            ></el-input>
          </el-form-item>
        </el-col>-->
        <!-- <el-col :span="12">
          <el-form-item label="支付银行" label-width="110px" prop="payChannel">
            <el-checkbox-group v-model="ruleForm.payChannel">
              <el-checkbox
                v-for="(item,index) in payChannelList"
                :label="item.code"
                :key="index"
              >{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>-->
        <el-col :span="12">
          <el-form-item label="项目备注" label-width="110px" prop="remark">
            <el-input v-model="ruleForm.remark" type="textarea" placeholder="请输入项目备注"></el-input>
          </el-form-item>
        </el-col>
        <Divider :title="'工资包审批设置'" :tag="`审批人和复核人必须在公众号上经过员工实名认证`"></Divider>
        <div class="tags" @click="addChecker">
          <el-button type="text" style="padding: 0">添加审核批准人员 +</el-button>
        </div>
        <el-row style="width: 100%" v-for="(item1, a) in checkList" :key="'index1-' + a">
          <el-col :span="12">
            <el-form-item :label="item1.name + (a + 1)" required style="width: 100%">
              <SelectUser :type="type" :isChecker="false" v-model="item1.value" :key="'a' + a"></SelectUser>
            </el-form-item>
          </el-col>
          <el-col :span="1" style="position: relative; top: 10px; left: 10px">
            <i
              class="el-icon-delete"
              style="color: red; font-size: 18px; cursor: pointer"
              @click="delItem(a)"
            ></i>
          </el-col>
        </el-row>
        <div class="tags">设置复核操作人员</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人" required style="width: 100%">
              <SelectUser
                :type="type"
                :isChecker="true"
                :key="'gzbysr'"
                v-model="checkerCode.value"
              ></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <Divider :title="'借款审批设置'" :tag="`审批人和复核人必须在公众号上经过员工实名认证`"></Divider>
        <div class="tags" @click="addLoan">
          <el-button type="text" style="padding: 0">添加审核批准人员 +</el-button>
        </div>
        <el-row style="width: 100%" v-for="(item2, b) in loanList" :key="'index2-' + b">
          <el-col :span="12">
            <el-form-item :label="item2.name + (b + 1)" required style="width: 100%">
              <SelectUser :type="type" :isChecker="false" v-model="item2.value" :key="'b' + b"></SelectUser>
            </el-form-item>
          </el-col>
          <el-col :span="1" style="position: relative; top: 10px; left: 10px">
            <i
              class="el-icon-delete"
              style="color: red; font-size: 18px; cursor: pointer"
              @click="delLoan(b)"
            ></i>
          </el-col>
        </el-row>
        <div class="tags">设置复核操作人员</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人" required style="width: 100%">
              <SelectUser :type="type" :isChecker="true" :key="'jkysr'" v-model="loanCode.value"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <Divider :title="'还款审批设置'" :tag="`审批人和复核人必须在公众号上经过员工实名认证`"></Divider>
        <div class="tags" @click="addRepayment">
          <el-button type="text" style="padding: 0">添加审批批准人员 +</el-button>
        </div>
        <el-row style="width: 100%" v-for="(item3, c) in repaymentList" :key="'index3-' + c">
          <el-col :span="12">
            <el-form-item :label="item3.name + (c + 1)" required style="width: 100%">
              <SelectUser :type="type" :isChecker="false" v-model="item3.value" :key="'c' + c"></SelectUser>
            </el-form-item>
          </el-col>
          <el-col :span="1" style="position: relative; top: 10px; left: 10px">
            <i
              class="el-icon-delete"
              style="color: red; font-size: 18px; cursor: pointer"
              @click="delRepayment(c)"
            ></i>
          </el-col>
        </el-row>
        <div class="tags">设置复核操作人员</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人" required style="width: 100%">
              <SelectUser
                :type="type"
                :isChecker="true"
                :key="'hkysr'"
                v-model="repaymentCode.value"
              ></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <Divider :title="'结转审批设置'" :tag="`审批人和复核人必须在公众号上经过员工实名认证`"></Divider>
        <div class="tags" @click="addCarryforward">
          <el-button type="text" style="padding: 0">添加审核批准人员 +</el-button>
        </div>
        <el-row style="width: 100%" v-for="(item4, d) in carryforwardList" :key="'index4-' + d">
          <el-col :span="12">
            <el-form-item :label="item4.name + (d + 1)" required style="width: 100%">
              <SelectUser :type="type" :isChecker="false" v-model="item4.value" :key="'d' + d"></SelectUser>
            </el-form-item>
          </el-col>
          <el-col :span="1" style="position: relative; top: 10px; left: 10px">
            <i
              class="el-icon-delete"
              style="color: red; font-size: 18px; cursor: pointer"
              @click="delCarryforward(d)"
            ></i>
          </el-col>
        </el-row>
        <div class="tags">设置复核操作人员</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人" required style="width: 100%">
              <SelectUser
                :type="type"
                :isChecker="true"
                :key="'jzysr'"
                v-model="carryforwardCode.value"
              ></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <Checker
          :checkList="checkList"
          @checkListNew="checkListEdit($event)"
          :checkerCode="checkerCode"
        ></Checker>
        <Loan
          :loanList="loanList"
          :loanCode="loanCode"
          @loanListNew="loanListEdit($event)"
        ></Loan>
        <Carryforward
          :carryforwardList="carryforwardList"
          @carryforwardListNew="carryforwardListEdit($event)"
          :carryforwardCode="carryforwardCode"
        >
        </Carryforward>
        <Repayment
          :repaymentList="repaymentList"
          @repaymentListNew="repaymentListEdit($event)"
          :repaymentCode="repaymentCode"
        ></Repayment>-->

        <el-button type="primary" style="float: right" @click="goSubmit('ruleForm')">保存</el-button>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import XLSX from 'xlsx';
import { api } from '../../api/base/index';

export const projectAdd = api()('project.add.json');
import { userList } from '../../api';

export const projectInfo = api()('project.info.json');
export const projectEdit = api()('project.edit.json');
export default {
  components: {
    // Checker: () => import('./checker.vue'),
    // Loan: () => import('./loan.vue'),
    // Repayment: () => import('./repayment.vue'),
    // Carryforward: () => import('./carryforward'),
    SelectUser: () => import('../task/selectUser.vue'),
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      List: [],
      uploadFiles: [],
      payChannelList: [
        { name: '网商银行', code: 'm1', },
        { name: '平安银行', code: 'p1', },
      ],
      checkList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],

      loanList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      repaymentList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      carryforwardList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      checkerCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },

      loanCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      repaymentCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      carryforwardCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      ruleForm: {
        name: '',
        subjectCode: '',
        contractMoney: '',
        remark: '',
        companyName: '',
        // payChannel: []
      },
      rules: {
        name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        subjectCode: [
          { required: true, message: '请选择项目负责人', trigger: 'blur' },
        ],
        companyName: [
          { required: true, message: '请输入合作企业', trigger: 'blur' },
        ],
        // payChannel: [
        //   { required: true, message: '请选择支付银行', trigger: 'blur' },
        // ]
        // type: [{ required: true, message: '请输入项目类型', trigger: 'blur' }],
      },
      isEdit: false,
      code: '',
      userList: [],
      type: '项目'
    };
  },
  async created () {

    this.userLogoData();
    this.code = this.$route.query.code;
    if (this.code) {
      this.title = '编辑项目';
      await this.logoData();
      this.isEdit = true;
    } else {
      this.title = '添加项目';
      this.isEdit = false;
    }
    console.log(this.isEdit);
  },
  methods: {
    delItem (i) {
      if (this.checkList.length === 1) {
        return this.$message.error('至少保留一个审核人！');
      }
      this.checkList = this.checkList.filter((it, index) => index !== i);
      console.log(this.checkList);
      this.$emit('update:checkListNew', this.checkList);
    },
    addChecker () {
      this.checkList.push({
        name: '审批人',
        value: {
          subjectAccount: '',
        },
      });
      this.$emit('update:checkListNew', this.checkList);
    },
    delLoan (i) {
      if (this.loanList.length === 1) {
        return this.$message.error('至少保留一个审核人！');
      }
      this.loanList = this.loanList.filter((it, index) => index !== i);
      this.$emit('update:loanListNew', this.loanList);
    },
    addLoan () {
      this.loanList.push({
        name: '审批人',
        value: {
          subjectAccount: '',
        },
      });
      this.$emit('update:loanListNew', this.loanList);
    },
    delRepayment (i) {
      if (this.repaymentList.length === 1) {
        return this.$message.error('至少保留一个审核人！');
      }
      this.repaymentList = this.repaymentList.filter(
        (it, index) => index !== i
      );
      this.$emit('repaymentListNew', this.repaymentList);
    },

    addRepayment () {
      this.repaymentList.push({
        name: '审批人',
        value: {
          subjectAccount: '',
        },
      });
    },
    delCarryforward (i) {
      if (this.carryforwardList.length === 1) {
        return this.$message.error('至少保留一个审核人！');
      }
      this.carryforwardList = this.carryforwardList.filter(
        (it, index) => index !== i
      );
      this.$emit('carryforwardListNew', this.carryforwardList);
    },

    addCarryforward () {
      this.carryforwardList.push({
        name: '审批人',
        value: {
          subjectAccount: '',
        },
      });
    },
    // repaymentListEdit(msg) {
    //   this.repaymentList = msg;
    // },
    // carryforwardListEdit(msg) {
    //   this.carryforwardList = msg;
    // },
    async importExcel (param) {
      this.uploadFiles = [];
      this.fileName = param.userName;
      const arr = await this.getExcelHeaders(param)

      arr.forEach((item, i) => {
        this.List.push({
          'key': item[i],
          'value': item
        })
      })
      console.log(this.List)

      // for (let i = 0; i < arr.length; i++) {
      //   console.log(arr[i]);
      //   this.List.push({
      //     key: i,
      //     label: arr[i],
      //   });
      // }
      // console.log(this.List);

    },
    getExcelHeaders (file) {
      const getHeaderRow = (sheet) => {
        const headers = []
        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C = range.s.c
        for (C = range.s.c; C <= range.e.c; ++C) {
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: 0 })]
          if (cell && cell.t) {
            headers.push(XLSX.utils.format_cell(cell))
          }
        }
        return headers
      }
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: 'binary',
          });
          resolve(getHeaderRow(this.wb.Sheets.sheet1));
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    async userLogoData () {
      const params = {
        keywords: '',
        pageNumber: 1,
        pageSize: 10000,
      };
      const res = await userList(params);

      this.userList = res.list.filter(it => it.isAuthenticated);
    },

    async logoData () {
      const params = {
        code: this.code,
      };
      const rs = await projectInfo(params);
      this.userInfo = rs;
      Object.keys(this.ruleForm).forEach((it) => {
        this.ruleForm[it] = rs[it];
      });
      let _checkList = [];

      rs.taskViewers.split(',').forEach((it) => {
        _checkList.push({
          name: '审批人',
          value: {
            subjectAccount: it,
          },
        });
      });
      this.checkList = _checkList;
      this.checkerCode.value.subjectAccount = rs.taskReviewer;
      let _loanList = [];
      rs.borrowViewers.split(',').forEach((it) => {
        _loanList.push({
          name: '审批人',
          value: {
            subjectAccount: it,
          },
        });
      });
      this.loanList = _loanList;
      this.loanCode.value.subjectAccount = rs.borrowReviewer;
      console.log(rs.borrowReiewers, 1111);
      let _carryforwardList = [];
      console.log(this.carryforwardList);
      rs.carryOverViewers.split(',').forEach((it) => {
        _carryforwardList.push({
          name: '审批人',
          value: {
            subjectAccount: it,
          },
        });
      });
      this.carryforwardList = _carryforwardList;

      this.carryforwardCode.value.subjectAccount = rs.carryOverReviewer;
      let _repaymentList = [];
      rs.payBackViewers.split(',').forEach((it) => {
        _repaymentList.push({
          name: '审批人',
          value: {
            subjectAccount: it,
          },
        });
      });
      this.repaymentList = _repaymentList;
      this.repaymentCode.value.subjectAccount = rs.payBackReviewer;
    },
    goSubmit (formName) {
      console.log(this.ruleForm);
      if (
        this.checkList[0].value.subjectAccount === '' ||
        this.loanList[0].value.subjectAccount === '' ||
        this.carryforwardList[0].value.subjectAccount === '' ||
        this.repaymentList[0].value.subjectAccount === ''
      ) {
        return this.$message.error('请先选择审批人！');
      }
      if (
        !this.checkerCode.value.subjectAccount ||
        !this.loanCode.value.subjectAccount ||
        !this.carryforwardCode.value.subjectAccount ||
        !this.repaymentCode.value.subjectAccount
      ) {
        return this.$message.error('请选择验收人员！');
      } else {
        const checks = this.checkList
          .map((it) => it.value.subjectAccount)
          .join(',');
        const loans = this.loanList
          .map((it) => it.value.subjectAccount)
          .join(',');
        const carryforwards = this.carryforwardList
          .map((it) => it.value.subjectAccount)
          .join(',');
        const repayments = this.repaymentList
          .map((it) => it.value.subjectAccount)
          .join(',');
        this.$refs[formName].validate(async (valid) => {
          const params = {
            ...this.ruleForm,

            taskViewers: checks,

            taskReviewer: this.checkerCode.value.subjectAccount,
            borrowViewers: loans,
            borrowReviewer: this.loanCode.value.subjectAccount,
            carryOverViewers: carryforwards,
            carryOverReviewer: this.carryforwardCode.value.subjectAccount,
            payBackViewers: repayments,
            payBackReviewer: this.repaymentCode.value.subjectAccount,
          };
          if (valid) {
            if (this.isEdit) {
              await projectEdit({ ...params, code: this.code });
              this.$message.success('编辑成功');
            } else {
              await projectAdd(params);
              this.$message.success('项目创建成功');
            }
            window.history.back(-1);
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scope>
.tags {
  font-size: 14px;
  margin: 10px 0 10px 100px;
  color: #409eff;
}
</style>